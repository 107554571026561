var rex_php_tags = new RegExp("<\\?php(?:[\\n\\r]|.)+?\\?>(?!\"|'')", "gim");
var rex_script_tags = new RegExp("<script(?:[\\n\\r]|.)+?\\/script>", "gim");
var rex_comments_tags = new RegExp("<!--(?:[\\n\\r]|.)+?-->", "gim");

// Strips php contents /!\ THIS DELETES PHP TAGS AND EVERYTHING IN BETWEEN /!\
function stripPhpContents(str) {
    return str.replace(rex_php_tags, '');
}

// Strips js contents /!\ THIS DELETES SCRIPT TAGS AND EVERYTHING IN BETWEEN /!\
function stripJSContents(str) {
    return str.replace(rex_script_tags, '');
}

// Strips html comments /!\ THIS DELETES COMMENTS TAGS AND EVERYTHING IN BETWEEN /!\
function stripHTMLComments(str) {
    return str.replace(rex_comments_tags, '');
}

function stripCombo(str) {
    var output = stripHTMLComments(str);
    output = stripJSContents(output);
    output = stripPhpContents(output);

    return output;
}

// Shows button's underlying loader and triggers defined action
function waitingProcess(loader_btn, action, id_form) {
    var $this = $(this),
        $loader = $(loader_btn);

    // Pour hideLoader
    elmt = $(this);
    loader = $loader;
    prev_display = elmt.css('display');

    this.style.display = 'none';

    if (!$loader.is(':visible')) {
        $loader.show();
        switch (action) {
            case 0:
                window.setTimeout(ajax_checkAllForm, 1000, tabAuthTop, 1, path_relative_root);
                break;

            case 1:
                window.setTimeout(ajax_checkAllForm, 1000, tabLostPwdTop, 1, path_relative_root);
                break;

            case 2:
                window.setTimeout(ajaxDeconnect, 1000, path_relative_root);
                break;

            case 3:
                window.setTimeout(checkAllForm, 1000, tabError, 0, path_relative_root, id_form);
                break;

            case 4:
                window.setTimeout(ajax_checkAllForm, 1000, tabAuth, 1, path_relative_root);
                break;

            case 5:
                window.setTimeout(ajax_checkAllForm, 1000, tabLostPwd, 1, path_relative_root);
                break;

            case 6:
                window.setTimeout(checkFormParrainage, 1000);
                break;

            case 7:
                if (checkAllForm(tabErrorGrattez, 0, path_relative_root, id_form)) {

                    window.setTimeout(processGrattezGagnez(), 1000);
                }
                break;
        }
    }
}

// Checks any form
function ajax_checkAllForm(tabError, blockMenuTopConnexionInfobulle, path_relative_root) {
    if (checkAllForm(tabError, blockMenuTopConnexionInfobulle)) {
        if (tabError[0][1] && tabError[0][1] == 'clientMailAuthTop') {
            $.ajax({
                url: path_relative_root + create_link('ajax_login_allowed'),
                type: 'get',
                data: {
                    mail: document.getElementById(tabError[0][1]).value,
                    mdp: document.getElementById(tabError[1][1]).value,
                    is_top: true
                },
                success: function (res) {
                    var url, index_deb, index_fin, provenance;
                    if (res[0] !== '0') {
                        url = window.location.pathname;
                        index_deb = url.lastIndexOf('/');
                        index_fin = url.lastIndexOf('.');
                        provenance = url.substring(index_deb + 1, index_fin);

                        if (provenance === 'connexion') {
                            window.location.replace(path_relative_root + create_link('client_index'));
                        } else if (provenance === 'identification') {
                            window.location.replace(path_relative_root + 'livraison-kiala.php');
                        } else {
                            window.location.reload();
                        }
                    } else {
                        document.getElementById('AuthTopErr').style.display = 'block';
                        $('.loader').css('display', 'none');
                    }
                }
            });
        } else if (tabError[0][1] && tabError[0][1] == 'mailLostTop') {
            var mail = document.getElementById(tabError[0][1]).value;

            $.ajax({
                url: path_relative_root + create_link('ajax_email_exist'),
                type: 'get',
                data: {
                    mail: mail
                },
                success: function (res) {
                    if (res == 1) {
                        $.ajax({
                            url: path_relative_root + create_link('ajax_send_new_password_link'),
                            type: 'get',
                            data: {
                                mail: mail
                            },
                            succes: function () {
                                show_lightbox('popup_confirm_reinitialisation_mdp');
                                document.getElementById('lostpwdTopErr').style.display = 'none';
                                document.getElementById('lostpwdTopSuccesTitle').style.visibility = 'hidden';
                                hideLoader();
                            }
                        });
                    } else {
                        document.getElementById('lostpwdTopErr').style.display = 'block';
                        hideLoader();
                    }
                }
            });
        } else if (tabError[0][1] && tabError[0][1] == 'clientMailAuth') {
            $.ajax({
                url: path_relative_root + create_link('ajax_login_allowed'),
                type: 'get',
                data: {
                    mail: document.getElementById(tabError[0][1]).value,
                    mdp: document.getElementById(tabError[1][1]).value,
                    is_top: false
                },
                success: function (res) {
                    hideLoader();
                    if (res == 1) {
                        $('#auth').submit();
                        return true;
                    } else {
                        document.getElementById('AuthErrErr').style.display = 'block';
                        return false;
                    }
                }
            });
        } else if (tabError[0][1] && tabError[0][1] == 'mailLost') {
            var mail = document.getElementById(tabError[0][1]).value;
            $.ajax({
                url: path_relative_root + create_link('ajax_email_exist'),
                type: 'get',
                data: {
                    mail: mail
                },
                success: function (res) {

                    if (res == 1) {

                        $.ajax({
                            url: path_relative_root + create_link('ajax_send_new_password_link'),
                            type: 'get',
                            data: {
                                mail: mail
                            },
                            success: function (res) {
                                show_lightbox('popup_confirm_reinitialisation_mdp');
                                document.getElementById('lostpwdErr').style.display = 'none';
                                document.getElementById('lostpwdSuccesTitle').style.visibility = 'hidden';
                                hideLoader();
                                return false;
                            }
                        });
                    } else {
                        document.getElementById('lostpwdErr').style.display = 'block';
                        hideLoader();
                        return false;
                    }
                }
            });
        }
    } else {
        $('.loader').css('display', 'none');
    }
}

// Ensures this.value is non-empty
// Binds to HTMLInputElement
// Returns true if value is non-empy, false otherwise
function nonEmpty() {
    return !!this.value.trim();
}

// Adds "display" rule to bond element's style with a value of "block"
function show() {
    this.style.display = "block";
}

// Adds "display" rule to bond element's style with a value of "none"
function hide() {
    this.style.display = "none";
}

// Adds leading zeroes to a given value
function zeroPad(nbr, base) {
    var zpad;
    base = (parseInt(base) > 1) ? base : 10;
    zpad = (nbr < base) ? '0' : '';
    return zpad + nbr.toString(base).toUpperCase();
}

function parseArgs(args) {
    var obj = {},
        inc = 0,
        len = args.length;
    for (inc; inc < len; inc += 1) {
        obj['arg' + inc] = args[inc];
    }
    return obj;
}

function translate() {

    var o_data = parseArgs(arguments),
        s_resp = arguments[0];
    var path_relative_root = document.getElementById('path').value;
    $.ajax({
        url: path_relative_root + create_link('ajax_sprintf'),
        type: 'get',
        data: o_data,
        async: false,
        success: function (res) {
            s_resp = res
        }
    });

    return s_resp;
}

// Alters item quantity in cart
function changeQte(type) {

    var types = ['plus', 'less'],
        item, loader, qtty, ajax_url;

    if (types.includes(type)) {

        item = this.getValue('item_id', parseFloat);
        loader = document.getElementById('qte_loader_' + item);
        qtty = this.getValue('selected_qte', parseFloat);

        this.qtty_select_wrap.style.display = 'none';
        loader.style.display = 'initial';

        switch (type) {

            case 'plus':
                qtty += 1;
                break;

            case 'less':
                qtty -= 1;
                break;
        }

        $.ajax({
            url : path_relative_root + create_link('order_basket') ,
            type: 'post',
            data: {
                action: 'del',
                panierId: item,
                cad: 'non',
                pointcad: 0,
                qteProd: qtty,
                change_qte: true
            },
            cache: false,
            success: function (res) {

                try {
                    res = JSON.parse(res);
                } catch (e) {
                    console.error('JSON ERROR: could not parse data');
                    return false;
                }

                var nbProdcut = [];
                var totalProducts = 0;

                setTimeout(function(){
                    //Pour chacun des produits dans le panier, on récupère la quantité associée
                    $('.cart_main_table.basket .cart_product_line .selected_qte').each(function(){
                        nbProdcut.push(parseInt(this.value));
                    });
                    for (var i=0;i<nbProdcut.length;i++) {
                        totalProducts += nbProdcut[i];
                    }

                    text_cart_panier = Translator.translate('txt_mon_panier');

                    if(totalProducts <= 1){
                        text = Translator.translate('one_product');
                        text_cart = Translator.translate('item');
                    } else {
                        text = Translator.translate('2_product');
                        text_cart = Translator.translate('items');
                    };
                    //On indique la quantité de produit mise à jour
                    $('.cart_main_title.basket').html(text_cart_panier + ' <span class="nb_products">(' + totalProducts +' ' + text + ')</span>');
                    $('#cart_length').html(+ totalProducts +' ' + text_cart);

                     //update nb_products wishlist
                     var nbWishlist = $(".cart_main_table.wishlist .cart_product_line").length;
                     var nb_products_wishlist;

                     if (nbWishlist == 1) {
                         nb_products_wishlist = "(" + nbWishlist + " " + Translator.translate("one_product") + ")";
                     } else {
                         nb_products_wishlist = "(" + nbWishlist + " " + Translator.translate("2_product") + ")";
                     }

                     $(".cart_main_title.wishlist .nb_products").html(nb_products_wishlist);
                },250);

                res = $('<div />').html(res.datas);

                document.getElementById('main_cart_wrapper').innerHTML = $('#main_cart_wrapper', res).html();

                panier_tg_swiper();
                $('.mini_cart .subtotal .pricetag').html($('.order_total .price', res).html());

                var total = $('.selected_qte').val();

                //Actualisation du prix du top-cart
                var array_price = $('.bill_line.total_produit .pricetag').text().split('€');
                var totalPriceCents = '';

                // Récupération des variables
                if (array_price.length >= 2) {
                    totalPriceCents = array_price[1].replace(/\s/g, '') || '';
                }
                var totalPrice = array_price[0].replace(/\s/g, '');

                // Mise en place du prix
                $('.amounts .subtotal #cart_unit').text(totalPrice);

                if ($('.amounts .subtotal #cart_cent').length > 0)
                    $('.amounts .subtotal #cart_cent').text(totalPriceCents);
            }
        });
    }
}

// Reload product from ref
function loadProduct(new_id, type, page) {
    var parent_element = '#site_global_wrap';
    var prefix = '';

    if ($('#lightbox_achat_express').css('display') == 'block') {
        parent_element = '#lightbox_achat_express';
        prefix = '#lightbox_achat_express ';
    }

    var old_id = this.getValue('produit_id');
    var col_id = this.getValue(this.getValue('idCouleur'));
    var sze_id = this.getValue(this.getValue('idTaille'));
    var act = this.getValue('act');
    var produit_refext = this.getValue('produit_refext');
    var qte_itm = 1;

    if (this.elements.namedItem('qteProd')) {
        qte_itm = this.elements.namedItem('qteProd').value;
    }

    var is_model = this.getValue('hdn_is_model');
    var data = {
        produit_id: old_id,
        produit_principal: old_id,
        couleurProd: col_id,
        tailleProd: sze_id,
        act: act,
        qteProd: qte_itm,
        hdn_product_id: old_id,
        hdn_is_model: is_model,
        produit_id_new: new_id,
        type: type,
        type_page: (page !== undefined) ? page : 'product',
        produit_refext: produit_refext
    };

    $.ajax({
        type: 'post',
        url: path_relative_root + create_link('ajax_get_product_regroup_ref'),
        data: data,
        success: function (res) {

            try {
                res = $.parseJSON(res);
            } catch (e) {
                console.error('parseJSON');
                return;
            }

            if (res.success == 'ok') {

                var datas = res.datas;

                if (data.type_page == 'product') {

                    var btn_retour = $('#btn_retour_product').attr('href');
                    var $html_main = $(stripCombo(datas.html_main));
                    var $html_middle = $(stripCombo(datas.html_middle));
                    var color = $(prefix + "#ligne_couleur").html();

                    // Needed for "avis verifiés" reviews update
                    var avisVerifiesReviews = $('w-netreviews-comments', $html_main);
                    if ($('w-netreviews-comments', $html_main).length === 0) {
                        avisVerifiesReviews = $('#w-netreviews-comments', $html_main);
                    }
                    var product_refext = $('#produit_refext', $html_main).val();

                    var main_wrapper = $('.product_main_wrapper', $html_main);
                    var info_wrapper = $('.product_info_wrapper', $html_main);
                    var zoom_wrapper = $('#zoom_box_wrapper', $html_main);
                    var assocs_slider = $('.associations.you_may_like', $html_main);

                    $('.item_price_wrapper', $html_main).next('div').after($html_middle).remove();

                    var contents = $('<div />').html($html_main);

                    var video = $('.wrapper_video', contents);

                    $('.product_main_wrapper', parent_element).after(main_wrapper).remove();
                    $('.product_info_wrapper', parent_element).after(info_wrapper).remove();
                    $('#zoom_box_wrapper', '#zoomBox').after(zoom_wrapper).remove();
                    $(prefix + 'input.color').prop('checked', false);
                    $(prefix + 'input#color_' + data.couleurProd).prop('checked', true);
                    $('#videoBox', parent_element).remove();
                    $(prefix + '#shade').after(video);
                    $('.associations.you_may_like', parent_element).after(assocs_slider).remove();

                    getWishlistProducts();

                    if (prefix != '') {
                        $('.itm_highlights', parent_element).remove();
                        $('.wrapper_delivery', parent_element).remove();
                        $('#btn_retour_product', parent_element).remove();
                        $('#addToWishlistButtonContainer', parent_element).remove();
                        $('#shareWrapper', parent_element).remove();
                        $('#zoom_box_wrapper', parent_element).remove();
                        $('#visual_scroller_wrapper', parent_element).append($('.frz_nocache', parent_element));
                        $('#visual_scroller_wrapper', parent_element).append($('.pdt_icon_btm', parent_element));
                        $('#loupe', parent_element).remove();
                        $('#lightbox_stock_alert', parent_element).remove();
                        $('.find_retailer', parent_element).attr('onclick', 'location.href="' + path_relative_root + create_link('search_shop') + '"');
                    } else {

                        $('.itm_go_product', parent_element).remove();

                        var caracPanel = $('#caracPanel');
                        var descrPanel = $('#descrPanel');

                        $('.productVisu').css({
                            height: ($('.productVisu').width()) + "px"
                        });
                        $('#videoFrame').css({
                            height: ($('.productVisu').width()) + "px"
                        });

                        if ($('.desc_pannel').length > 2) {

                            $('#spec').css({
                                height: ($('.productVisu').innerHeight() / 5.2) + 'px'
                            });
                            $('.descriptionTxt').css({
                                height: ($('.productVisu').innerHeight() / 5.2) + 'px'
                            });
                            $('#toggleCarac').css({
                                display: 'flex'
                            });
                            $('#toggleDescr').css({
                                display: 'flex'
                            });
                        } else {
                            if (caracPanel.innerHeight() + descrPanel.innerHeight() > $('.productVisu').innerHeight()) {
                                if (caracPanel.innerHeight() > $('.productVisu').innerHeight() / 3) {
                                    if ($(window).width() <= 1024) {
                                        $('#spec').css({
                                            height: ($('.productVisu').innerHeight() / 4.6) + 'px'
                                        });
                                        $('.descriptionTxt').css({
                                            height: ($('.productVisu').innerHeight() / 4.6) + 'px'
                                        });
                                        $('#toggleCarac').css({
                                            display: 'flex'
                                        });
                                        $('#toggleDescr').css({
                                            display: 'flex'
                                        });
                                    } else if ($(window).width() < 1600) {
                                        $('#spec').css({
                                            height: ($('.productVisu').innerHeight() / 3.5) + 'px'
                                        });
                                        $('#toggleCarac').css({
                                            display: 'flex'
                                        });
                                    } else {
                                        $('#spec').css({
                                            height: ($('.productVisu').innerHeight() / 2.8) + 'px'
                                        });
                                        $('#toggleCarac').css({
                                            display: 'flex'
                                        });
                                    }
                                }
                            }
                        }
                    }

                    //on remet le lien en place
                    $(prefix + '#btn_retour_product').attr('href', btn_retour);

                    checkStockCases();
                    refreshProductPageElements($('form.product_info', '#site_global_wrap').get(0));

                    // Update "avis verifiés" reviews
                    updateReviews(avisVerifiesReviews, product_refext);

                    // Reload product description tabs animations
                    productDescAnimation();


                } else if (type_page == 'look') {

                    var $html_main = $(datas.main_content);

                    $('#ref_look_' + produit_principal).html($('#ref_look_' + produit_principal, $html_main).html());
                    $('#look_add_lightbox_' + produit_principal).html($('#look_add_lightbox_' + produit_principal, $html_main).html());

                    calculTotal('slide_look_detail');
                    $('#itm_thumb_list').empty();
                    var thumbnails = loadPrevisu();
                    parseThumbList(thumbnails);

                    var $id_current = id_prod.substring(id_prod.lastIndexOf("_") + 1);
                    var i = array_look.indexOf(parseInt($id_current));

                    if (i != -1) {

                        array_look.splice(i, 1);
                    }

                    // Une fois les informations chargés on vas mettre à jour le select des quantités avec le nouveau produit choisie
                    showQteListeGamme('liste_qte_' + datas.product.id, datas.product.id, path_relative_root);

                }

                if (document.getElementById("alma-widget") !== null) {
                    initAlmaWidget();
                }

                if (typeof nrSearchForStars !== "undefined" && $('.NETREVIEWS_PRODUCT_STARS').length) {
                    nrSearchForStars();
                }
            } else {
                $('#btn_error').fadeIn();
            }
        },
        complete: function () {

            // Reload Sliders
            initFpSliders();

            if (window.YT_Player !== undefined) {
                window.YT_Player.destroy();
                window.YT_Player = undefined;
                window.YT_vid_id = undefined;
            }

            getAvailableQuantity.call($('form.product_info', parent_element).get(0), data.produit_id_new, data.qteProd, '', data.couleurProd);

            fix_elements();

            $('#addToWishlistButton', parent_element).on('click', addToWishlist);

            // Update breadcrumb product name
            if ($('.breadcrumb.current span').length > 0 && $('#itm_name').text().length > 0) {
                $('.breadcrumb.current span').text($('#itm_name').text());
            }

            checkProductInWishlist();

            $('.netreviews_stars').on('click', function () {
                // Check if the element exists after the animation is complete
                if ($('#w-netreviews-comments').length > 0) {
                    $('html, body').animate({scrollTop: $('#w-netreviews-comments').offset().top-$("#site_head_wrap").height()}, 1000);
                } else {
                    console.warn('Netreviews element not found!');
                }
            });

            // Inject chosen color in product right part
            injectChosenColor();

            // Enable script for carac pic zoom on click
            displayCaracPic();
        }
    });
}

/* NETREVIEWS
    First switch, ID is in DOM, replace by ID
    After the first switch replace by tag name
*/
function updateReviews(avisVerifiesReviews, product_refext) {

    if ($("w-netreviews-comments").length > 0) {
        $("w-netreviews-comments", '#site_global_wrap').replaceWith(avisVerifiesReviews.wrap('<p/>').parent().html());
    } else {
        $("#w-netreviews-comments", '#site_global_wrap').replaceWith(avisVerifiesReviews.wrap('<p/>').parent().html());
    }
    // Reload vuejs netreviews
    if (typeof wShop !== 'undefined') {

        // Netreviews reviews
        if (wShop.$refs.wNetreviewsComments != undefined) {
            wShop.$refs.wNetreviewsComments.reload(product_refext);
        }
    }

    // STATIC NETREVIEWS SWIPER
    var swiperNetreviews = new Swiper('.swiperNetreviews', {
        'slidesOffsetBefore': 64,
        'slidesPerView': '2.3',
        'watchSlidesVisibility': true,
        'spaceBetween': 10,
        'navigation': {
            'nextEl': '.nextSlideFp',
            'prevEl': '.prevSlideFp',
        },
        'pagination': {
            'el': '.swiper-scrollbar',
            'type': 'progressbar',
            'clickable': true
        },
        'breakpoints': {
            '1290': {
                'slidesPerView': 2.3,
                'spaceBetween': 22,
            }
        }
    });
    // add it to the global scope
    window.swiperNetreviews = swiperNetreviews;
}

/**
 * @description Called upon color change in product page
 */
function onItemColorChange(form, is_regroup, itm_rr_id) {
    var control, prod_id, from_page;
    itm_rr_id = itm_rr_id !== undefined ? itm_rr_id : '';
    var is_wishlist = $('#wishlist').length > 0;
    var is_achat_express_v2 = ($('#is_achat_express_v2').val() == 1 && !is_wishlist);

    if (this.checked) {
        control = form.elements.namedItem(form.getValue('idCouleur'));
        control.value = this.value.trim();

        // If it is achat_express_v2
        if (is_achat_express_v2 && ($('[name="itm_id_' + control.value + itm_rr_id + '"]').length > 1)) {
            // Find the input_check checked and look at your parent to find the right name=itm_id_.. inside as there are more than one
            var checkedColor_input = $(form).find('.input_check:checked');
            var checkedColor_value = checkedColor_input.parent().find('[name="itm_id_' + control.value + itm_rr_id + '"]').val();
            prod_id = checkedColor_value;
        } else {
            prod_id = form.getValue('itm_id_' + control.value + itm_rr_id).trim();
        }

        var is_assoc_product = form.getValue('isAssocProduct') ? form.getValue('isAssocProduct') : 0;

        if (is_assoc_product == true) {
            from_page = 'assoc';
        } else {
            from_page = 'product';
        }

        if (is_regroup) {
            loadProduct.call(form, prod_id, 'couleur', from_page, itm_rr_id, 'colorChange');
        } else {
            // Check if the product is already in the wishlist
            if (!is_achat_express_v2) {
                checkProductInWishlist(prod_id, itm_rr_id);
            } else {
                loadProduct.call(form, prod_id, 'couleur', from_page, itm_rr_id, 'colorChange', false);
            }

            var img = $(this).data('pic');
            changeVisu('img_large', img);

            getAvailableQuantity.call(form, prod_id, form.getValue('qteProd'), '', $("#couleurProd_" + prod_id).val());

            $('.color_list .form_itm').removeClass('checked');
            $(this).parent('.form_itm').addClass('checked');

            // Define sizes that still have stock
            setAvailableSizes.call(this);
        }
    }
}

function setAvailableSizes() {
    if (window.json_colisages !== undefined) {
        var sizes_tab = window.json_colisages[$(this).val()];
        var size_box, is_disabled;
        var has_stock = false;

        for (size_id in sizes_tab) {
            if (sizes_tab.hasOwnProperty(size_id)) {
                size_box = $('.product_info_wrapper [name="itm_size"]').filter('[value="' + size_id + '"]');
                is_disabled = !sizes_tab[size_id];
                if (is_disabled) {
                    size_box.addClass('disabled');
                } else {
                    size_box.removeClass('disabled');
                    has_stock = true;
                }
            }
        }

        checkStockCases();
    }
}

// Refreshes available quantity
function getAvailableQuantity(id, qty, from, idCouleur, itm_rr_id, is_wishlist) {
    itm_rr_id = (itm_rr_id !== undefined) ? itm_rr_id : '';
    var form = (this instanceof HTMLFormElement) ? this : document.getElementById('prod_info_' + id + itm_rr_id);
    var list = $('.qte_select_wrapper', form).get(0);
    var plnb = form.getValue('produit_lot_nb');
    var pcol = form.getValue('idCouleur_' + id + '_' + itm_rr_id);
    var qty = (qty !== undefined) ? qty : 1;
    var is_achat_express = $('#is_achat_express_v2').val() == 1 && !is_wishlist;

    id = ( (id !== undefined) ? id : '' );

    if (typeof(pcol)=='undefined') {
        var pcol = form.getValue('idCouleur');
    }

    var val_color = form.getValue(pcol);
    if (val_color == '0') {
        val_color = 'none';
    }

    var psze = form.getValue('idTaille');
    var val_size = form.getValue(psze);
    if (val_size == '0' && !(plnb > 0)) {
        val_size = 'none';
    }

    if (idCouleur !== undefined) {
        val_color = idCouleur;
        $('#'+pcol).val(idCouleur);
        $( "#color_"+idCouleur ).attr( "checked", "checked" );
    }

    var data = {
        idprod : form.getValue('produit_id'),
        idcouleur : val_color,
        idtaille : val_size,
        json: '1'
    };

    if (from == 'basket') {
        data.suff = form.getValue('produit_id');
    }

    if (plnb) {
        data.produit_lot_nb = plnb;
    }

    var product_has_stock;

    $.ajax({
        url : path_relative_root + create_link('ajax_liste_quantity'),
        type : 'get',
        async : false, // Should be async but would then need a loader so...
        data : data,
        dataType: 'json',
        success : function (res) {
            var new_select;
            if (list) {
                list.innerHTML = res.content.trim();

                if (qty) {
                    new_select = form.elements.namedItem('qteProd');
                    if ($('[value="' + qty + '"]', new_select).length > 0) {
                        new_select.value = qty;
                    } else {
                        new_select.value = $('option:last', new_select).val();
                    }
                }
            }

            if ($("#date_du_ajax").val() == 0 && $("#date_au_ajax").val()) {
                $("#delivery_delay").hide();
            } else {
                $("#delivery_delay").show();
                $("#date_du").html($("#date_du_ajax").val());
                $("#date_au").html($("#date_au_ajax").val());
            }

            if ($('.txt_reason_borne').length > 0) {
                $('.info_magasin').html($('.txt_reason_borne').html());
                $('.info_magasin').show();
            }

            $('#liste_qte').html(res.content);
            $(".eclatProd").show();
            $(".precoDeliveryDate").show();

            var regexp_epuise = /Stock /;
            var txt_stock = $('#qteProd>option:selected').text().match(regexp_epuise) != null;

            if (!is_achat_express) {
                checkStockCases();
                refreshProductPageElements($('form.product_info', '#site_global_wrap').get(0));
            } else {
                if (res.stock_tampon == 0) {
                    if (wShop.$refs.wAlertestockForm) {
                        wShop.$refs.wAlertestockForm.reloadBloc += 1;
                    }

                    // DATALAYER V2
                    pushIntoDatalayer(res.dataLayer_object);
                    product_has_stock = false;
                } else {
                    // DATALAYER V2
                    pushIntoDatalayer(res.dataLayer_object);
                    product_has_stock = true;
                }
            }
        }
    });

    if (is_achat_express) {
        return product_has_stock;
    }
}

function refreshProductPageElements(qty, form) {

    var parent_element = '';

    if ($('#lightbox_achat_express').css('display') == 'block') {
        parent_element = '#lightbox_achat_express ';
    }

    $(parent_element + ".eclatProd").removeClass('cache');
    value_stock = $(parent_element + '#qteProd').val();

    var res_qte_val = $(parent_element + "#qteProd").val();
    var regex = /choisir une couleur/;

    if ($("#date_du_ajax").val() == 0 && $("#date_au_ajax").val()) {
        $(parent_element + "#delivery_delay").hide();
    } else {
        $(parent_element + "#delivery_delay").show();
        $(parent_element + "#date_du").html($("#date_du_ajax").val());
        $(parent_element + "#date_au").html($("#date_au_ajax").val());
    }

    // Si stock dispo
    if (res_qte_val !== undefined) {
        //Si pas de stock DB + Stock physique + pas de date d'arrivage = on affiche le picto "bientot dispo"
        if (value_stock === undefined && $(parent_element + "#stock_magasin").val() != 0 && $(parent_element + '#restock_date').length == 0) {
            $(parent_element + ".eclat_nouveaute").removeClass('cache');
            $(parent_element + ".eclat_epuise").addClass('cache');
            $(parent_element + "#bloc_add_alert").show();
            $(parent_element + "#bloc_add_alert").removeClass('cache');
            $(parent_element + "#bloc_add_basket").hide();
            $(parent_element + ".eclat_product_last_fiche_produit").addClass('cache');
            $(parent_element + ".eclatProd").addClass('cache');
        //Si pas de stock DB + pas de stock physique + date d'arrivage = on affiche le picto "bientot dispo"
        } else if (value_stock === undefined && $(parent_element + "#stock_magasin").val() != 0 && $(parent_element + '#restock_date').length > 0) {
            $(parent_element + ".eclat_nouveaute").removeClass('cache');
            $(parent_element + ".eclat_epuise").addClass('cache');
            $(parent_element + "#bloc_add_alert").show();
            $(parent_element + "#bloc_add_alert").removeClass('cache');
            $(parent_element + "#bloc_add_basket").hide();
            $(parent_element + ".eclat_product_last_fiche_produit").addClass('cache');
            $(parent_element + ".eclatProd").addClass('cache');
        //Si pas de stock DB + pas de stock physique + date d'arrivage = on affiche le picto "bientot dispo"
        } else if (value_stock === undefined && $(parent_element + "#stock_magasin").val() == 0 && $(parent_element + '#restock_date').length > 0) {
            $(parent_element + ".eclat_nouveaute").removeClass('cache');
            $(parent_element + ".eclat_epuise").addClass('cache');
            $(parent_element + "#bloc_add_alert").show();
            $(parent_element + "#bloc_add_alert").removeClass('cache');
            $(parent_element + "#bloc_add_basket").hide();
            $(parent_element + ".eclat_product_last_fiche_produit").addClass('cache');
            $(parent_element + ".eclatProd").addClass('cache');
        //Si pas de stock DB + pas de stock physique + pas de date d'arrivage = on affiche le picto "expiré"
        } else if ($(parent_element + "#stock_magasin").val() == 0 && value_stock === undefined && $(parent_element + '#restock_date').length == 0) {
            $(parent_element + ".eclat_nouveaute").addClass('cache');
            $(parent_element + ".eclat_epuise").removeClass('cache');
            $(parent_element + "#bloc_add_alert").hide();
            $(parent_element + "#bloc_add_alert").addClass('cache');
            $(parent_element + "#bloc_add_basket").hide();
            $(parent_element + ".eclat_product_last_fiche_produit").addClass('cache');
            // $(parent_element + ".no_available_see_in_store").show();
            $(parent_element + ".eclatProd").addClass('cache');
        }
    }

    if ($(parent_element + '.restock_date').length > 0 && $(parent_element + '.info_magasin').length > 0) {
        $(parent_element + '.info_magasin').append($(parent_element + '.restock_date'));
    }

    if (qty) {
        if (typeof (form) != 'undefined') {
            new_select = form.elements.namedItem('qteProd');
            if ($('[value="' + qty + '"]', new_select).length > 0) {
                new_select.value = qty;
            } else {
                new_select.value = $('option:last', new_select).val();
            }
        }
    }
}

// YOUTUBE LOGIC
var YT_Player, YT_vid_id;

function onYouTubeIframeAPIReady() {

    YT_Player = new YT.Player('popup_video', {
        videoId: YT_vid_id,
        playerVars: {
            rel: 0
        }
    });

    if ($('#videoBox').is(':visible')) {
        YT_Player.playVideo();
    }
}

function initYTPlayer() {

    var tag = document.createElement('script');

    tag.id = 'YT_player_script';
    tag.src = "https://www.youtube.com/iframe_api";

    document.body.appendChild(tag);
}

// close video popin FP
function stop_video() {

    var video = $('#videoBox');

    video.css({
        display: 'block',
        opacity: 1
    }).animate({
        opacity: 0
    }, function () {

        $('#shade').stop(true, true).fadeOut('slow', function () {
            if (YT_Player !== undefined) {
                YT_Player.seekTo(0).stopVideo();
            }

            video.css({
                display: 'none'
            });
        });
    });
}
// show video popin FP
function show_video(vid, type) {
    var video = $('.otherViewImg');
    var img = $('.fp_img_content #img_large');
    $(window).scrollTop(0);

    if (type == 2 || type == 3) {
        if ($('#visu_vimeo').length != 0) {
            $('#jqzoom .fp_img_content').append($('#visu_vimeo').html());
        }
        // Show the video back
        video.css({ 'display': 'block' });
        // // Hide the image when showing video
        img.css({ 'display': 'none' });
    } else {
        if (!YT_Player) {
            YT_vid_id = vid;
            if (!document.getElementById('YT_player_script')) {
                initYTPlayer();
            } else {
                onYouTubeIframeAPIReady();
            }
        }
        $('#shade').off('click', stop_video).after($('#videoBox'));
        $('#shade').stop(true, true).fadeIn('slow', function () {

            $('#videoBox').css({
                display: 'block',
                opacity: 0
            }).animate({
                opacity: 1
            }, function () { });

            $('#shade').one('click', stop_video);
        });
    }
}

function waitingAddToBasket_legacy(produit_id, suff, panier_id, titreObjet, idTaille, lastTaille, idCouleur, lastCouleur, toEval, page_name, path_web, enable_omniture, is_not_produit, is_lightbox_specifique) {

    var id = '';
    if (is_not_produit == true) {
        id += '_' + produit_id;
    }

    if (is_lightbox_specifique) {
        add_produit_specifique(produit_id, idCouleur, idTaille);
    } else {
        window.setTimeout('addToBasket_legacy("' + produit_id + '", "' + suff + '", "' + panier_id + '", "' + titreObjet + '", "' + idTaille + '", "' + lastTaille + '", "' + idCouleur + '", "' + lastCouleur + '","' + toEval + '", "' + page_name + '", "' + path_web + '", ' + enable_omniture + ', ' + is_not_produit + ');', 1000);
    }

    $("#bloc_btn_active" + id).attr('class', 'cache');
    $("#bloc_btn_loader" + id).attr('class', 'f_right');
}

function addToBasket_legacy(produit_id, suff, panier_id, titreObjet, idTaille, lastTaille, idCouleur, lastCouleur, toEval, page_name, path_web, enable_omniture, is_not_produit) {
    var id = '';

    if (is_not_produit == true) {
        id += '_' + produit_id;
    }

    prix_id = 'prixU';
    if (is_not_produit == true) {
        var code_color = $('#couleurUnique_' + produit_id).text();
        var nom_color = $('#couleurUnique_' + produit_id).text();
        var prix_id = 'prixU_' + produit_id;
    }

    var array_panier = newUpdatePanier_legacy(produit_id, suff, panier_id, document.getElementById(prix_id).value, path_web, titreObjet, idTaille, lastTaille, idCouleur, lastCouleur);

    if (array_panier != false) {
        var type = '';

        if (array_panier[1] == 'false' || array_panier.length == 0) {
            type = 'err';
        }

        omnitureEvent = '';
        var response = ajax_file(path_web + 'ajax_show_basket.php');
        $("#show_top_cart").html(response);
        CartShow();

        if (array_panier[1] === 0) {
            $('#nb_article').html(array_panier[1]);
            omnitureEvent = '';
        } else if (array_panier[1] == 1) {
            $('#nb_article').html(array_panier[1]);
            $('#panier').attr('href', path_web + create_link('order_basket')).attr('style', 'cursor:pointer;');

            omnitureEvent = 'scOpen,scAdd'; // le premier produit ajout� au panier
        } else {
            $('#nb_article').html(array_panier[1]);
            $('#panier').attr('href', path_web + create_link('order_basket')).attr('style', 'cursor:pointer;');

            omnitureEvent = 'scAdd'; // il y a d�j� un produit au panier
        }

        newshowmodal(array_panier[1], type, path_web, is_not_produit, produit_id);

        $("#bloc_btn_active" + id).attr('class', '');
        $("#bloc_btn_loader" + id).attr('class', 'f_right cache');

        if (array_panier[1] > 0 && enable_omniture == true) {
            void(s.t());
            s.pageName = "'" + page_name + "'";
            s.products = ";" + produit_id;
            s.events = omnitureEvent;
            void(s.t());
        }
    } else {
        $("#bloc_btn_active" + id).attr('class', '');
        $("#bloc_btn_loader" + id).attr('class', 'f_right cache');

    }

    eval(toEval);

    if (array_panier != false) {
        if (navigator.userAgent.indexOf('MSIE') != -1 && navigator.userAgent.indexOf('6.') != -1) {
            var svn = document.getElementsByTagName("SELECT");
            for (a = 0; a < svn.length; a++) {
                svn[a].style.visibility = "hidden";
            }
        }
    }

    return false;
}

function newUpdatePanier_legacy(idProduit, i, paniervirtuelId, prixU, path_web, titreObjet, idElTaille, lastTaille, idElCouleur, lastCouleur) {
    if (i != '') {
        i = '_' + i;
        prixU = $('#prixU' + i).val();
    }

    var couleurs = document.getElementById('couleurProd' + i);
    var tailles = document.getElementById('tailleProd' + i);
    var $alertbox = $('#alertbox1');
    var res = '';
    var html_err = '';
    var flagError = false;
    if ($('#lightbox_achat_express').length > 0) {
        from_range = true;
    } else {
        from_range = false;
    }

    html_err = '<div class="txt_alert"></div><div class="rosette"></div>';
    html_err += '<div class="wrapper_btn"><a onclick="closeMultiShad();" href="#" class="btn_alert">OK</a></div>';
    if ($alertbox.length == 0) {
        $alertbox = $('<div id="alertbox1">' + html_err + '</div>');
        $('body').append($alertbox.hide());
    }

    if (tailles && tailles.value == '') {
        res = ajax_file(path_web + 'ajax_sprintf.php?arg1=js_error_size');
        flagError = true;

    } else if (couleurs && couleurs.value == '') {
        res = ajax_file(path_web + 'ajax_sprintf.php?arg1=js_error_couleur');
        flagError = true;

    } else if (document.getElementById('qteProd' + i) && document.getElementById('qteProd' + i).value == '') {
        res = ajax_file(path_web + 'ajax_sprintf.php?arg1=js_error_quantity');
        flagError = true;
    }

    if (flagError) {
        $alertbox.find('.txt_alert').html(res);
        openMultiShad('alertbox1', 'fixed');
        return false;
    } else {

        var qte = 0;

        if ($('#qteProd' + i)) {
            qte = $('#qteProd' + i).val();
        }

        var idCouleur = 0;
        var idTaille = 0;

        if (couleurs && couleurs.value != 'none') idCouleur = couleurs.value;
        if (tailles && tailles.value != 'none') idTaille = tailles.value;

        var array_response = new Array();
        var response = ajax_file(path_web + 'ajax_upd_panier.php?prixu=' + prixU + '&idprod=' + idProduit + '&idcouleur=' + idCouleur + '&idtaille=' + idTaille + '&qte=' + qte + '&panierId=' + paniervirtuelId);

        if (response) {
            array_response = response.split("//");
            var designation = array_response[0];
            if (titreObjet.match('^[0-9]')) {
                if (designation == 1) {
                    designation += ' lot de';
                } else {
                    designation += ' lots de';
                }
            }

            designation += ' ' + titreObjet;

            if (document.getElementById(idElTaille)) {
                if (document.getElementById(idElTaille).selectedIndex) {
                    designation += ' - Taille ' + document.getElementById(idElTaille).options[document.getElementById(idElTaille).selectedIndex].text;
                } else if (lastTaille != '') {
                    designation += ' - Taille ' + lastTaille;
                }
            }

            if (document.getElementById(idElCouleur)) {
                if (document.getElementById(idElCouleur).selectedIndex) {
                    designation += ' - ' + document.getElementById(idElCouleur).options[document.getElementById(idElCouleur).selectedIndex].text;
                } else if (lastCouleur != '') {
                    designation += ' - ' + lastCouleur;
                }
            }
            array_response[0] = designation;
        }
        return array_response;
    }
}

// Limits the length of an input
function limitInputLength(max) {
    this.value = this.value.slice(0, max);
    return this;
}

function changeBlocProductLook(product_id) {
    if (product_id !== '' && product_id !== undefined) {
        $('#content_wrapper .item_product_look').hide();
        $('#content_wrapper #look_prod_' + product_id).show();
    }
}

function createDatalayer(prod_id, url) {
    if (typeof productlist_gondole != 'undefined'){
        for ( var i = 0 ; i < productlist_gondole.length; i++) {
            prod = productlist_gondole[i];
            if (prod.id == prod_id){
                window.list = prod['list'];
                delete prod['list'];
                dataLayer.push({
                    'event': 'productClick',
                    'ecommerce': {
                        'click': {
                            'actionField': {'list':list}, // Optional list property.
                            'products': [prod]
                        }
                    },
                    'eventCallback': 'function() { document.location = url}',
                });
            }
        }
    }
}

/***** FUNCTION PUSH INTO DATALAYER  *****/

function pushIntoDatalayer (data) {
    if (typeof(data) == 'object') {
        window.dataLayer.push(data);
    } else if (typeof(data) == 'string') {
        window.dataLayer.push(JSON.parse(data));
    }
}

/***** PROD ASSOCS DATA LAYERS *****/

function dataLayer_associated_product () {

    if (window.$('.otherProduct')) {
        var product_id = this.getAttribute('data-produit-id') ? event.currentTarget.getAttribute('data-produit-id') : '';
        var productObj = null;
        if (product_id !== '') {
            if ( data_layer_products.length > 0 ) {
                data_layer_products.forEach(function(item){
                    if ( item.id == product_id ) {
                        productObj = item;
                    }
                })
            }
            var prod = productObj;
            if (productObj !== null && productObj !== undefined ) {
                dataLayer.push({
                    'event': 'productClick',
                    'ecommerce': {
                        'click': {
                            'actionField': {'list': productObj['category']},      // Optional list property.
                            'products': [prod]
                        }
                    },
                    'eventCallback': "function () {document.location = productObj.url}"
                });
            }
        }
    }
}

setTimeout(function() {
    var tabLabel = document.querySelectorAll("#subscriptionForm > .optin-select-line > div .w-input-label")
    for (var elem of tabLabel) {
        elem.classList.add("normal-time")
    }
}, 400);

// Alters item quantity in cart
function chanQteList() {
    var item = this.getValue('item_id', parseFloat);
    var loader = document.getElementById('qte_loader_' + item);

    var old_qte = parseInt($(this).find('.selected_qte').val());
    var new_qte = parseInt($(this).find('.qnt_prod').val());
    var rank = $(this).index('.qte_selector');
    this.qtty_select_wrap.style.display = 'none';
    loader.style.display = 'block';

    if (window.dataLayer !== undefined) {
        var product = productFieldObject[rank];

        if (new_qte > old_qte) {
            product.quantity = (new_qte - old_qte);
            dataLayer.push({
                "event": "addToCart",
                "ecommerce": {
                    'currencyCode': siteCurrency.code,
                    "add": {
                        "products": [product]
                    }
                }
            });
        } else if (new_qte < old_qte) {
            product.quantity = (old_qte - new_qte);
            dataLayer.push({
                "event": "removeFromCart",
                "ecommerce": {
                    "remove": {
                        "products": [product]
                    }
                }
            });
        }
    }

    $.ajax({
        url : path_relative_root + create_link("order_basket"),
        type : 'post',
        data : {
            action : 'del',
            panierId : item,
            cad  : 'non',
            pointcad : 0,
            qteProd : new_qte,
            change_qte : true
        },
        cache : false,
        success : function (res) {
            try {
                res = JSON.parse(res);
            } catch (e) {
                console.error('JSON ERROR: could not parse data');
                return false;
            }

            res = $('<div />').html(res.datas);

            document.getElementById('main_cart_wrapper').innerHTML = $('#main_cart_wrapper', res).html();

            // Cart slider initialization
            var sliderCart = $('#main_cart_wrapper #basketAssocWrapper');

            if (sliderCart.length) {
                sliderCart.remove();
            }

            //update top cart
            var response = ajax_file(create_link('ajax_show_basket'));
            document.getElementById('cart_top').innerHTML = response;

        },
        complete : function() {
            var total = $('#cart_top .link_item_cart').attr('data-cart'),
            cart = Translator.translate('mon_panier'),
            text;
            if(total <= 1){
                text = Translator.translate('one_product');
            } else {
                text = Translator.translate('2_product');
            };

             //update nb_products wishlist
            var nbWishlist = $(".cart_main_table.wishlist .cart_product_line").length;
            var nb_products_wishlist;

            if (nbWishlist == 1) {
                nb_products_wishlist = "(" + nbWishlist + " " + Translator.translate("one_product") + ")";
            } else {
                nb_products_wishlist = "(" + nbWishlist + " " + Translator.translate("2_product") + ")";
            }


            $(".cart_main_title.wishlist .nb_products").html(nb_products_wishlist);

            panier_tg_swiper();
            getWishlistProducts();
            initializeVueJs();
        }
    });
}

    /* Addings in TUNNEL *******************************************************/
    if ($(".step_2 #cart_total .bill_line.total_produit_dropdown:visible").length || $(".checkout.in_tunnel #cart_total .bill_line.total_produit_dropdown:visible").length || $(".step_2_0 #cart_total .bill_line.total_produit_dropdown:visible").length || $(".customer.cart #cart_total .bill_line.total_produit_dropdown:visible").length) {
        $('#cart_total .bill_line.total_produit_dropdown').hide();
        $( ".bill_line.total_produit.toggle" ).after( "<div onclick='seeMoreProd()' class='seeMore bgSprite aft rightarrow'><span></span></div>" );
    }
    /********************************************************************************************* */

    function seeMoreProd() {
        $(".bill_line.total_produit.toggle").click();
    }

    // This function bellow allows closing Woosmap when clicking outside the suggestions container.

    function clickOutsideWoosmapHandler(bodyClass, parentSelector) {
        if (document.body.classList.contains(bodyClass) && document.body.classList.contains('fr')) {
            document.addEventListener('click', function(event) {
                var autocompleteContainer = document.getElementById('search_cp-completion');
                if (autocompleteContainer) {
                    if (!autocompleteContainer.contains(event.target)) {
                        var parentInput = autocompleteContainer.closest(parentSelector).querySelector('input');
                        if (document.activeElement !== parentInput) {
                            closeAutocomplete(autocompleteContainer);
                        }
                    }
                }
            });

            function closeAutocomplete(autocompleteContainer) {
                autocompleteContainer.style.display = 'none';
            }
        }
    }

    // Using the function for different contexts
    clickOutsideWoosmapHandler('in_tunnel', '.w-address-input');
    clickOutsideWoosmapHandler('customer', '.w-address-input');
    clickOutsideWoosmapHandler('store_locator', '.field_address');
    clickOutsideWoosmapHandler('product_page', '.field_address');
