var autocompleteMap = '';
$(function() {

    if ($('.store_locator.homepage').length > 0) {

        var searchElem = $('.store_locator.homepage .autocomplete_addr')[0];
        var countryFilter = $(searchElem).data('country');
        var option = {
            types: ['geocode']
        };
        if (countryFilter !== undefined && countryFilter !== '') {
            option.componentRestrictions = { country: countryFilter };
        }
        // Initialisation de l'autocomplete google
        if (google != undefined) {
            autocompleteMap = new google.maps.places.Autocomplete(searchElem, option);

            var geocoder = new google.maps.Geocoder();
            google.maps.event.addListener(autocompleteMap, 'place_changed', function() {
                // Recherche par rapport à l'adresse renseigné
                geocoder.geocode({'address': searchElem.value}, function(results, status) {
                    if (status === google.maps.GeocoderStatus.OK) {
                        document.forms['storelocHome'].elements['lat'].value = results[0].geometry.location.lat();
                        document.forms['storelocHome'].elements['lng'].value = results[0].geometry.location.lng();
                        $('.storeloc_form').submit();
                    }
                });
            });
        }
    }
});

/**
 * Pour vérifier si le champs du stoleLocator est bien renseigné
 * @param formElt
 * @returns {boolean}
 */
function checkHomeStoreLocator(formElt) {
    $('#search_shop_errs').text('');
    var form = $(formElt);
    var autocomplete = $('.autocomplete_addr', form);

    if (autocomplete.val() === '' || $('form.storeloc_form .autocomplete_addr').val() == '') {
        autocomplete.addClass('inputErr');
        $('#search_shop_errs').text(Translator.translate('js_wrong_adresse'));
        return false;
    } else {
        var zipcode_rex = new RegExp("^[0-9]+$");

        // Si jamais on ne passe que un code postal on rajoute le pays (sinon il ne trouvera pas l'adresse)
        if (zipcode_rex.test(autocomplete.val())) {
            var adresse = autocomplete.val() + ', France';
            autocomplete.val(adresse);
        }
        if (google != undefined) {
            google.maps.event.trigger(autocompleteMap, 'place_changed');
        }
        // Si la personne ne rentre que un zip code alors on va
        autocomplete.removeClass('inputErr');
        return true;
    }
}